import React, {PropsWithChildren, useEffect, useState} from "react";
import {User, UserContext} from "../../context/UserContext";
import {UserLoginButton} from "../../context/UserLoginButton";

import {
  GoogleLogin,
  googleLogout
} from "@react-oauth/google";
import {ApiError, DefaultService} from "../../api-generated";
import {useSnackbar} from "notistack";
import {Button, Container, CssBaseline, Skeleton} from "@mui/material";

export interface AccountingAuthProviderProps {
}

export const AccountingAuthProvider = (props: PropsWithChildren<AccountingAuthProviderProps>) => {
  const { enqueueSnackbar } = useSnackbar();

  const [googleToken, setGoogleToken] = useState<string | undefined | null>()
  const [user, setUser] = useState<User | undefined | null>();

  useEffect(() => {
    if (user === undefined) {
      DefaultService.meMeGet().then((value) => {
        setUser({uuid: value.id});
      }).catch((reason: ApiError) => {
        setUser(null);
        if (reason.status === 403) {
          setGoogleToken(undefined);
        }
      })
    }
  }, [user]);

  useEffect(() => {
    if (googleToken === null) {
      enqueueSnackbar("Google didn't manage to log you in.", {variant: "error"});
    } else if (googleToken !== undefined) {
      DefaultService.authGoogleCookieAuthGoogleCookiePost({
        token: googleToken,
      }).then((value) => {
        setUser({uuid: value.id});
        enqueueSnackbar('Welcome! You are logged in.', {variant: "success"});
      }).catch((reason: ApiError) => {
        setUser(null);
        enqueueSnackbar("We couldn't log you in.", {variant: "error"});
      })
    }
  }, [googleToken, enqueueSnackbar]);

  if (user === undefined) {
    return (
      <>
        <CssBaseline />
        <Container maxWidth="sm">
          <Skeleton variant="text" sx={{fontSize: '3rem', mt: 6}} />
          <Skeleton variant="text" sx={{fontSize: '1rem', mt: 2}} />
          <Skeleton variant="text" sx={{fontSize: '1rem' }} />
          <Skeleton variant="rectangular" width={150} height={40} sx={{mt: 4}} />
        </Container>
      </>
    );
  }

  return (
    <>
      {user && (
        <Button onClick={() => {
          DefaultService.authLogoutAuthLogoutGet().then(() => {
            googleLogout();
            setGoogleToken(undefined);
            setUser(null);
            enqueueSnackbar("You are logged out.", {variant: "info"});
         }).catch((reason: ApiError) => {
            enqueueSnackbar("Weird. We couldn't log you out.", {variant: "warning"});
          })
        }}>Log out</Button>
      )}

      <UserContext.Provider value={user || null}>
        <UserLoginButton.Provider value={(user === null && googleToken === undefined) ? (
          <GoogleLogin
            onSuccess={(credentialResponse) => setGoogleToken(credentialResponse.credential)}
            onError={() => setGoogleToken(null)}
            useOneTap
            auto_select
          />
        ) : null}>
          {props.children}
        </UserLoginButton.Provider>
      </UserContext.Provider>

    </>
  );
};
