/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Equation } from '../models/Equation';
import type { GoogleAuthBody } from '../models/GoogleAuthBody';
import type { HTTPAuthorizationCredentials } from '../models/HTTPAuthorizationCredentials';
import type { PowensConnectionSyncedCallbackBody } from '../models/PowensConnectionSyncedCallbackBody';
import type { UserInfo } from '../models/UserInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * Auth Logout
     * Clear cookies keeping user logged-in.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static authLogoutAuthLogoutGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/logout',
        });
    }

    /**
     * Auth Google Cookie
     * Provide cookies keeping google-user authenticated.
     * @param requestBody
     * @returns UserInfo Successful Response
     * @throws ApiError
     */
    public static authGoogleCookieAuthGoogleCookiePost(
        requestBody: GoogleAuthBody,
    ): CancelablePromise<UserInfo> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/google/cookie',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Auth Google Token
     * Provide a token to authenticate a google-user.
     * @param requestBody
     * @returns HTTPAuthorizationCredentials Successful Response
     * @throws ApiError
     */
    public static authGoogleTokenAuthGoogleTokenPost(
        requestBody: GoogleAuthBody,
    ): CancelablePromise<HTTPAuthorizationCredentials> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/google/token',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Me
     * Provide information about authenticated user.
     * @returns UserInfo Successful Response
     * @throws ApiError
     */
    public static meMeGet(): CancelablePromise<UserInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/me',
        });
    }

    /**
     * Redirect Powens Connect Callback
     * Redirect from powens to accounting frontend.
     * @param code
     * @param tosDeclined
     * @returns any Successful Response
     * @throws ApiError
     */
    public static redirectPowensConnectCallbackRedirectPowensConnectCallbackGet(
        code?: (string | null),
        tosDeclined?: (boolean | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/redirect/powens/connect/callback',
            query: {
                'code': code,
                'tos_declined': tosDeclined,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Redirect Powens Connect
     * Redirect from accounting frontend to powens.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static redirectPowensConnectRedirectPowensConnectGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/redirect/powens/connect',
        });
    }

    /**
     * Callback Powens Connection Synced
     * Ingest connection state.
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static callbackPowensConnectionSyncedCallbackPowensConnectionSyncedPost(
        requestBody: PowensConnectionSyncedCallbackBody,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/callback/powens/connection/synced',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Me Equation
     * @param at
     * @returns Equation Successful Response
     * @throws ApiError
     */
    public static meEquationMeEquationGet(
        at?: (string | null),
    ): CancelablePromise<Equation> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/me/equation',
            query: {
                'at': at,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Trigger Error
     * @returns any Successful Response
     * @throws ApiError
     */
    public static triggerErrorDebug500Get(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/debug/500',
        });
    }

    /**
     * Index
     * @returns any Successful Response
     * @throws ApiError
     */
    public static indexGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/',
        });
    }

}
