import React, {useContext, useEffect, useState} from "react";

import {
  Alert,
  AlertTitle,
  Container,
  Grid,
  CssBaseline,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Typography, TextField,
} from "@mui/material";

import PendingIcon from '@mui/icons-material/Pending';
import dayjs from 'dayjs';

import {DisplayAmounts} from './components/accounting/Amounts';
import {ManagePowensButton} from "./components/powens/Redirect";
import {UserContext} from "./context/UserContext";
import {UserLoginButton} from "./context/UserLoginButton";
import {Equation, ApiError, DefaultService, Term} from "./api-generated";
import {enqueueSnackbar} from "notistack";
import {parseApiAmounts} from "./domainmodel";


export interface EquationTermRowsProps {
  name: string,
  term: Term,
}

const EquationTermRows = ({name, term}: EquationTermRowsProps) => {

  return (
    <>
      <TableRow>
        <TableCell align="left">
          <Typography mx={0}>
            <strong>{name}</strong>
          </Typography>
        </TableCell>
        <TableCell align="right">
          <strong>
            <DisplayAmounts
              color={true}
              normal_sign={term.normal_sign}
              amounts={parseApiAmounts(term.amount)}
            />
          </strong>
        </TableCell>
      </TableRow>
      {term.contributions.length === 0 ? (
        <TableRow>
          <TableCell colSpan={2}>
            <Grid container direction="column" alignItems="left">
              <Grid item>
                <Typography mx={4} variant="body1" color="textSecondary">
                  {name} is empty for now, that's okay.
                </Typography>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      ) : term.contributions.map(
        ({account_id, normal_sign, amount, account}) => (
          <TableRow key={account_id}>
            <TableCell>
              <Typography mx={4}>
                {account.original_name || account_id}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <DisplayAmounts
                color={true}
                normal_sign={normal_sign}
                amounts={parseApiAmounts(amount)}/>
            </TableCell>
          </TableRow>
        ))
      }
    </>
  );


}


export interface EquationTableProps {
  equation: Equation,
}

const EquationTable = ({equation}: EquationTableProps) => {
  return (

    <TableContainer>
      <Table aria-label="equation table">
        <TableHead>
          <TableRow>
            <TableCell>Accounts</TableCell>
            <TableCell align="right">Balance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <EquationTermRows name="Equity" term={equation.equity}/>
          <EquationTermRows name="Assets" term={equation.assets}/>
          <EquationTermRows name="Liabilities" term={equation.liabilities}/>
        </TableBody>
      </Table>
    </TableContainer>

  );
}

export interface EquationOverviewProps {
}

const EquationOverview = () => {


  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [equation, setEquation] = useState<undefined | Equation>();

  useEffect(() => {
    DefaultService.meEquationMeEquationGet(selectedDate.toISOString()).then((value) => {
      setEquation(value);
    }).catch((reason: ApiError) => {
      setEquation(undefined);
      enqueueSnackbar("We couldn't get your account data.", {variant: "error"});
    })
  }, [selectedDate]);

  // FIXME: We currently have no way of getting a "Reference" value,
  // for multi-currency amounts. We need it when computing ratios.
  // This will be implemented later, for now it's wonky.
  const fixme_get_approx_value_of_term = (term: Term) => term.normal_sign * Array.from(
    parseApiAmounts(term.amount).values()
  ).reduce((accumulator, [numerator, denominator]) => accumulator + numerator / denominator, 0);

  const equityMultiplier = equation === undefined ? NaN : fixme_get_approx_value_of_term(equation.assets) / fixme_get_approx_value_of_term(equation.equity);

  return (
    equation === undefined ? (
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <PendingIcon color="disabled" fontSize="large"/>
        </Grid>
        <Grid item>
          <Typography variant="body1" color="textSecondary">
            We'll show your accounts as soon as we see them.
          </Typography>
        </Grid>
      </Grid>
    ) : (
      <>
        <Grid container direction="row" justifyContent="space-between"
              alignItems="center" my={4}>

          <Grid item>
            <TextField
              id="xxx-datetime-local"
              label="Balance at"
              type="datetime-local"
              value={selectedDate.format("YYYY-MM-DDThh:mm")}
              onChange={(e) => setSelectedDate(dayjs(e.target.value))}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item>

            {isNaN(equityMultiplier) || (
              <>
                <Typography variant="h3" component="span">
                  {Math.round(100 * equityMultiplier)}%
                </Typography>
                <Typography>
                  Equity Multiplier
                </Typography>
              </>
            )}

          </Grid>

        </Grid>
        <EquationTable equation={equation}/>
      </>
    )
  );

}

function App() {

  const user = useContext(UserContext);
  const loginButton = useContext(UserLoginButton);

  return (
    <>
      <CssBaseline/>
      <Container maxWidth="sm">

        <Box sx={{my: 2}}>
          <Alert severity="warning">
            <AlertTitle>Under heavy development</AlertTitle>
            This is very experimental, don't expect anything stable for now!
          </Alert>
        </Box>

        {
          user === null && (
            <p>Feel free to <strong>sign in</strong> to explore our features.
              Fetching bank accounts is a lot of work. We need you to sign in so
              we don't need to do that so often.
            </p>
          )
        }

        {
          loginButton || (
            <Box sx={{my: 2}}>
              <ManagePowensButton disabled={user === null}/>
            </Box>
          )
        }

        {
          user && (
            <Box sx={{my: 2}}>
              <EquationOverview/>
            </Box>
          )
        }

      </Container>
    </>
  )
    ;
}

export default App;
