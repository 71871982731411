import {Button, ButtonProps} from "@mui/material";
import React from "react";


const REDIRECT_URL = process.env.REACT_APP_OPENAPI_BASE + "/redirect/powens/connect";

export const ManagePowensButton = (props: ButtonProps) => {
  return (
    <Button variant="contained" href={REDIRECT_URL} {...props}>Manage Accounts</Button>
  );
}
