import {Amounts} from "../../domainmodel";
import {Chip} from "@mui/material";


export interface DisplayAmountsProps {
  amounts: Amounts,
  normal_sign?: number,
  color?: boolean,
}

export const DisplayAmounts = ({amounts, normal_sign, color = false}: DisplayAmountsProps) => {
  return (
    <span>{
      Array.from(amounts, ([currency, [nominator, denominator]]) => (
        <Chip
          key={currency}
          color={!color ? "default" : (normal_sign ?? 1) * nominator < 0 ? "error" : "success"}
          variant={color ? "outlined" : "filled"}
          label={
            ((normal_sign ?? 1) * (nominator / denominator)).toLocaleString('en-US', {
              style: 'currency',
              currency: currency,
            })
          }
        />
      ))
    }</span>
  );
}
