import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {OpenAPI} from "./api-generated";
import {SnackbarProvider} from "notistack";
import {
  AccountingAuthProvider
} from "./components/accounting/AccountingAuthProvider";
import {GoogleOAuthProvider} from "@react-oauth/google";

console.log(process.env);

if (process.env.REACT_APP_OPENAPI_BASE !== undefined) {
  OpenAPI.BASE = process.env.REACT_APP_OPENAPI_BASE;
  OpenAPI.WITH_CREDENTIALS = true;
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <SnackbarProvider>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || "missing-google-client-id"}>
        <AccountingAuthProvider>
          <App />
        </AccountingAuthProvider>
      </GoogleOAuthProvider>
    </SnackbarProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
